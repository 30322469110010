<template>
  <div class="container-fluid main-container">
    <div class="row">
      <div class="col-3">
        <form @submit.prevent="onSubmit">
          <div class="card mb-2">
            <div class="card-body">
              <div class="mb-2">
                <select class="form-select" v-model="leveType">
                  <option value="0">选择理符类型</option>
                  <option value="1">佣兵作业</option>
                  <option value="2">采矿工</option>
                  <option value="3">园艺工</option>
                  <option value="4">捕鱼人</option>
                  <option value="5">刻木匠</option>
                  <option value="6">锻铁匠</option>
                  <option value="7">铸甲匠</option>
                  <option value="8">雕金匠</option>
                  <option value="9">制革匠</option>
                  <option value="10">裁衣匠</option>
                  <option value="11">炼金术士</option>
                  <option value="12">烹调师</option>
                  <option value="16">黑涡团</option>
                  <option value="17">双蛇党</option>
                  <option value="18">恒辉队</option>
                </select>
              </div>
              <div class="mb-2">
                <div class="input-group">
                  <span class="input-group-text">理符等级</span>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="最小"
                    min="0"
                    max="90"
                    v-model="minLevel"
                  />
                  <input
                    type="number"
                    class="form-control"
                    placeholder="最大"
                    min="0"
                    max="90"
                    v-model="maxLevel"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6 pe-1">
                  <button class="btn btn-primary d-block" type="submit">
                    搜索
                  </button>
                </div>
                <div class="col-6 ps-1">
                  <button
                    class="btn btn-danger d-block"
                    type="button"
                    @click="resetForm"
                  >
                    重置
                  </button>
                </div>
              </div>
              <div class="text-center">
                <span class="text-muted">搜索结果上限 50 条</span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-4 main-col">
        <div class="card card-content overflow-hidden">
          <div class="card-header">搜索结果</div>
          <div
            class="card-body text-center"
            v-if="isLoading || hasError || leveResult.length == 0"
          >
            <span class="text-muted" v-if="isLoading"
              ><span
                class="spinner-border spinner-border-sm text-primary"
              ></span>
              搜索中...</span
            >
            <p
              class="text-muted text-center"
              v-if="!isLoading && leveResult.length == 0"
            >
              <span class="text-danger" v-if="hasError">{{ errorMsg }}</span>
              <span class="text-muted" v-else>无搜索结果</span>
            </p>
          </div>

          <div class="list-group list-group-flush overflow-auto" v-else>
            <a
              v-for="leve in leveResult"
              :key="leve.id"
              :href="'#/leve/' + leve.id"
              class="list-group-item list-group-item-action item-list-item"
            >
              <div class="mb-1">
                <span class="badge info-badge me-1">{{
                  leve.classjobCategory
                }}</span>
                <span class="badge info-badge me-1">Lv.{{ leve.level }}</span>
                <template v-if="leve.craft_leve != null">
                  <span
                    class="badge bg-secondary"
                    v-for="item in leve.craft_leve"
                    :key="item.item.id"
                    >{{ item.item.name }} x{{ item.count }}</span
                  >
                </template>
              </div>
              <div class="mb-1">
                {{ leve.name }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-5 main-col">
        <div class="card card-content">
          <div class="card-body overflow-auto" v-if="leveData != null">
            <div class="mb-1">
              <span class="badge bg-secondary me-1">
                Lv.{{ leveData.classjobLevel }}
              </span>
              <b>{{ leveData.name }}</b>
            </div>
            <span class="badge rounded-pill bg-secondary full-width-badge">
              基本信息
            </span>
            <div class="row">
              <div class="col-6">
                <span class="badge info-badge">职业</span>
                {{ leveData.classjobCategory }}
              </div>
              <div class="col-6">
                <span class="badge info-badge">消耗限额</span>
                {{ leveData.allowanceCost }}
              </div>
              <div class="col-6">
                <span class="badge info-badge">接受地点</span>
                {{ leveData.placename_issue }}
              </div>
              <div class="col-6" v-if="leveData.npc != null">
                <span class="badge info-badge">交付NPC</span>
                {{ leveData.npc.name }}
              </div>
              <div class="col-12" v-if="leveData.start != null">
                <span class="badge info-badge">开始地点</span>
                {{ leveData.start.map }} X: {{ leveData.start.x }}, Y:
                {{ leveData.start.y }}
              </div>
              <div class="col-12" v-if="leveData.npc != null">
                <span class="badge info-badge">交付地点</span>
                {{ leveData.npc.map }} X: {{ leveData.npc.x }}, Y:
                {{ leveData.npc.y }}
              </div>
            </div>
            <span class="badge rounded-pill bg-secondary full-width-badge">
              理符奖励
            </span>
            <div class="row">
              <div class="col-6">
                <span class="badge info-badge">经验</span>
                {{ leveData.expReward }}
              </div>
              <div class="col-6">
                <span class="badge info-badge">金币</span>
                {{ leveData.gilReward }}
              </div>
              <div class="col-6" v-if="leveData.craft_leve != null">
                <span class="badge info-badge">可缴纳次数</span>
                {{ parseInt(leveData.craft_leve.repeats) + 1 }}
              </div>
              <div class="col-6" v-if="leveData.craft_leve != null">
                <button
                  type="button"
                  class="btn item-info-btn btn-xs item-badge"
                  @click="openCalcModal(leveData.id, leveData.name)"
                >
                  <b>升级计算器</b>
                </button>
              </div>
            </div>
            <template v-if="leveData.craft_leve != null">
              <span class="badge rounded-pill bg-secondary full-width-badge">
                缴纳物品
              </span>
              <template
                v-for="item in leveData.craft_leve.items"
                :key="item.item.id"
              >
                <div>
                  <span class="badge info-badge me-1">缴纳物品</span>
                  <a :href="'#/item/' + item.item.id">
                    <img
                      :src="this.$root.getIconURL(item.item.icon)"
                      class="rounded item-icon"
                    />
                  </a>
                  <span class="ms-1">
                    {{ item.item.name }} x{{ item.count }}
                  </span>
                </div>
                <span class="badge rounded-pill bg-secondary full-width-badge">
                  物品原料
                </span>
                <div>
                  <a :href="'#/item/' + item.item.id">
                    <img
                      :src="this.$root.getIconURL(item.item.icon)"
                      class="rounded item-icon"
                    />
                  </a>
                  <span class="ms-1">
                    {{ item.item.name }} x{{ item.recipe[0].amount }}
                  </span>
                </div>
                <div class="ms-3">
                  <ItemIngredient
                    v-for="ingredient in item.recipe[0].ingredients"
                    :key="ingredient.id"
                    :item="ingredient"
                    :secondary="false"
                  />
                </div>
              </template>
            </template>
          </div>
          <div class="card-body" v-else>
            <p class="text-muted text-center" v-if="isLeveLoading">
              <span
                class="spinner-border spinner-border-sm text-primary"
              ></span>
              搜索中...
            </p>
            <p class="text-muted text-center" v-if="!isLeveLoading">
              <span class="text-danger" v-if="hasLeveError">{{
                leveErrorMsg
              }}</span>
              <span class="text-muted" v-else>请选择理符</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="leve-calc" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">理符升级计算器</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ currentCalcItem.name }}</p>
          <p class="text-success" v-if="calcResult != null">
            所需数量: {{ calcResult.count }}, 可获得金币: {{ calcResult.gil }}
          </p>
          <p class="text-muted">注: 不含制作获得经验</p>
          <hr />
          <div class="mb-3">
            <label for="start-level" class="form-label">初始等级</label>
            <input
              type="number"
              class="form-control"
              id="start-level"
              min="1"
              max="90"
              v-model="calcStartLevel"
            />
          </div>
          <div class="mb-3">
            <label for="target-level" class="form-label">目标等级</label>
            <input
              type="number"
              class="form-control"
              id="target-level"
              min="1"
              max="90"
              v-model="calcTargetLevel"
            />
          </div>
          <button
            type="button"
            class="btn btn-outline-primary d-block"
            style="width: 100%"
            @click="calc"
            :disabled="isCalculating ? 'disabled' : null"
          >
            计算
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemIngredient from "@/components/ItemIngredient.vue";

export default {
  name: "Leve",
  components: {
    ItemIngredient,
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
      errorMsg: "",

      isLeveLoading: false,
      hasLeveError: false,
      leveErrorMsg: "",

      leveType: 0,
      minLevel: null,
      maxLevel: null,

      leveResult: new Array(),
      leveData: null,

      isCalculating: false,
      currentCalcItem: {},
      calcResult: null,
      calcStartLevel: null,
      calcTargetLevel: null,
    };
  },
  methods: {
    onSubmit() {
      this.leveResult = new Array();
      this.isLoading = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "leve.php?method=searchLeve", {
          assignmentType: this.leveType,
          minLevel: this.minLevel,
          maxLevel: this.maxLevel,
        })
        .then(function (response) {
          if (response.data.success) {
            self.leveResult = response.data.data;
            self.isLoading = false;
          } else {
            self.hasError = true;
            self.errorMsg = "搜索失败: " + response.data.error;
            self.isLoading = false;
          }
        })
        .catch(function (error) {
          self.hasError = true;
          self.errorMsg = "搜索失败: " + error;
          self.isLoading = false;
        });
    },
    resetForm() {
      this.leveType = 0;
      this.minLevel = null;
      this.maxLevel = null;
    },
    openCalcModal(id, name) {
      this.currentCalcItem = {
        id: id,
        name: name,
      };

      this.calcResult = null;
      this.calcStartLevel = null;
      this.calcTargetLevel = null;

      var myModalEl = document.querySelector("#leve-calc");
      var modal = this.bootstrap.Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.show();
    },
    calc() {
      this.isCalculating = true;

      var self = this;
      this.axios
        .post(this.apiBaseURL + "leve.php?method=calcLeveExp", {
          id: this.leveData.id,
          startLv: this.calcStartLevel,
          targetLv: this.calcTargetLevel,
        })
        .then(function (response) {
          self.isCalculating = false;

          if (response.data.success) {
            self.calcResult = {
              count: response.data.count,
              gil: response.data.gil,
            };
          } else {
            alert("计算失败: " + response.data.error);
          }
        })
        .catch(function (error) {
          self.isCalculating = false;

          alert("计算失败: " + error);
        });
    },
  },
  activated() {
    var id = this.$route.params.id > 0 ? this.$route.params.id : 0;
    if (id > 0) {
      if (
        this.leveData == null ||
        (this.leveData != null && this.leveData.id != id)
      ) {
        this.leveData = null;

        this.isLeveLoading = true;
        this.hasLeveError = false;
        this.leveErrorMsg = "";

        var self = this;
        this.axios
          .post(this.apiBaseURL + "leve.php?method=getLeve", {
            id: id,
          })
          .then(function (response) {
            if (response.data.success) {
              self.isLeveLoading = false;

              self.leveData = response.data.data;
            } else {
              self.isLeveLoading = false;
              self.hasLeveError = true;
              self.leveErrorMsg = response.data.error;
            }
          })
          .catch(function (error) {
            self.isLeveLoading = false;
            self.hasLeveError = true;
            self.leveErrorMsg = error;
          });
      }
    }
  },
  beforeRouteUpdate(to) {
    var id = to.params.id > 0 ? to.params.id : 0;
    if (id > 0) {
      if (
        this.leveData == null ||
        (this.leveData != null && this.leveData.id != id)
      ) {
        this.leveData = null;

        this.isLeveLoading = true;
        this.hasLeveError = false;
        this.leveErrorMsg = "";

        var self = this;
        this.axios
          .post(this.apiBaseURL + "leve.php?method=getLeve", {
            id: id,
          })
          .then(function (response) {
            if (response.data.success) {
              self.isLeveLoading = false;

              self.leveData = response.data.data;
            } else {
              self.isLeveLoading = false;
              self.hasLeveError = true;
              self.leveErrorMsg = response.data.error;
            }
          })
          .catch(function (error) {
            self.isLeveLoading = false;
            self.hasLeveError = true;
            self.leveErrorMsg = error;
          });
      }
    }
  },
};
</script>

<style scoped>
.main-col {
  min-height: 550px;
  max-height: calc(100vh - 140px);
}

.card-content {
  height: 100%;
}

button.d-block {
  width: 100%;
}

.full-width-badge {
  width: 100%;
}

.info-badge {
  background-color: #11a3c0;
}

.notice-badge {
  background-color: #1c6fbd;
}

.item-icon {
  width: 24px;
  height: 24px;
}

.item-info-btn {
  color: #fff;
  background-color: #1c6fbd;
  border-color: #1c6fbd;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 0.8;
}
</style>